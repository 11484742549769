.calendar-container {
    min-height: 25rem;
    height: calc(80vh - 4rem);
    min-width: 20rem;
    width: 42.5vw;
    display: grid;
    grid-template-rows: 90% 10%;
    grid-template-columns: 100%;
    grid-template-areas: 
        'calendar'
        'calendarFooter';
    padding: 5px;
    background-color: rgb(14, 14, 43);
    background-image: url('../img/gradients/dark1.jpg');
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.5);
    color: white;
}

.calendar {
    grid-area: calendar;
}

.calendar-footer {
    grid-area: calendarFooter;
    display: grid;
    grid-auto-flow: column;
}

.calendar-submit {
    background-color: transparent;
    color: white;
    font-size: 1.5em;
    box-shadow: 0 0 5px white;
}

.calendar-submit:hover.enabled {
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    font-size: 1.6em;
    background-color: rgba(255, 255, 255, 0.1);
}

.calendar-submit:active.enabled {
    font-size: 1.5em;
}

.calendar-submit.disabled {
    background-color: rgba(100, 100, 100, 0.5);
}

@media (max-aspect-ratio: 1/1) {
    .calendar-container {
        min-height: 20rem;
        height: 45vh;
        min-width: 15rem;
        width: 88vw;
    }
    .calendar-submit {
        font-size: 1em;
    }
    .calendar-submit:hover {
        font-size: 1.1em;
    }
    .calendar-submit:active {
        font-size: 1em;
    }
}