:root {
    --dark-blue-values: 24, 25, 105;
    --dark-blue: rgb(var(--dark-blue-values));
}
.grid-container {
    height: 100%;
    width: 100%;
    overflow: auto;
    background-size: cover;
    background-position: center;
    display: grid;
    grid-template-areas: 
        ". .           .           .               ."
        ". header      header      header          ."
        ". .           .           .               ."
        ". chatSection .           calendarSection ."
        ". .           .           .               ."
        ". dataSummary dataSummary dataSummary     ."
        ". .           .           .               .";
    grid-template-columns: 5vw minmax(min-content, max-content) 5vw minmax(min-content, max-content) 5vw;
    grid-template-rows: minmax(3vh, 30px) 1.5rem minmax(4vh, 40px) minmax(min-content, max-content) minmax(3vh, 30px) minmax(min-content, max-content) minmax(3vh, 30px);
}
.header {
    grid-area: header;
    position: relative;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-auto-flow: column;
}
.header .back-button {
    font-size: 1.5rem;
    color: white;
    margin-right: 0.5em;
}
.header .event-title {
    display: inline;
    font-size: 1.5rem;
    color: white;
    margin: 0;
    padding-bottom: 0.1em;
}
.share-icon {
    color: rgba(var(--dark-blue-values), 0.5);
}
.share-icon:hover {
    color: white;
}
.header .share-icon {
    font-size: 1em;
}
.share-icon.hidden {
    opacity: 0;
    transform: translateX(10px) scale(0); /* or any other initial state */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
.share-icon.visible {
    opacity: 1;
    transform: translateX(10px) scale(1);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}
:root {
    --toastify-color-dark: rgb(16, 9, 59);
    --toastify-color-progress-dark: rgba(200, 200, 230, 0.8);
}
.header .save-status {
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    background-color: rgba(71, 71, 133, 0.3);
    padding: 4px 10px 3px 10px;
    border-radius: 5px;
    font-size: 1.1em;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    place-items: center;
}
.chat-section {
    grid-area: chatSection;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
}
.calendar-section {
    grid-area: calendarSection;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    z-index: 0;
}
.data-summary-section {
    grid-area: dataSummary;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
}
.data-summary {
    place-self: center;
    background-color: rgba(100, 100, 100, 0.3);
    color: white;
    padding: 0.5em;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.1em;
}
.loading {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    place-items: center;
}
.help-icon {
    color: var(--dark-blue);
}
.help-icon:hover {
    color: white;
}
.help-icon.desktop-only {
    position: absolute;
    bottom: 30px;
    right: 30px;
    height: 20px;
}
.desktop-only {
    display: inline-block;
}
.mobile-only {
    display: none;
}
.help-modal {
    position: absolute;
    top: 20vh;
    left: 20vw;
    right: 20vw;
    bottom: 20vh;
    background-color: black;
    box-shadow: 0 0 20px grey;
    border-radius: 5px;
    border: 1px solid black;
    z-index: 5;
}
.help-modal-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-areas:
        "."
        "modalContent"
        ".";
    grid-template-columns: 100%;
    grid-template-rows: 10% 70% 20%;
    align-items: center;
}
.help-modal-content {
    grid-area: modalContent;
    font-family: Helvetica, Arial, sans-serif;
    color: white;
    padding: 2em;
    font-size: 1em;
    line-height: 1.1em;
}
.help-modal-content h2 {
    font-size: 1em;
}
.help-modal-content ol,ul {
    padding-left: 1em;
}
.help-overlay {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;    
}
@media (max-aspect-ratio: 1/1) {
    .grid-container {
        grid-template-areas:
            ". .               .               ."
            ". header          header          ."
            ". .               .               ."
            ". chatSection     chatSection     ."
            ". .               .               ."
            ". calendarSection calendarSection ."
            ". .               .               ."
            ". dataSummary     dataSummary     ."
            ". .               .               .";
        grid-template-columns: 20px minmax(min-content, max-content) 30px 20px;
        grid-template-rows: 10px 1rem 12px minmax(min-content, max-content) 15px minmax(min-content, max-content) 10px minmax(min-content, max-content) 10px;
    }
    .header .back-button {
        font-size: 1em;
    }
    .header .event-title {
        font-size: 1em;
    }
    .data-summary {
        font-size: 1em;
    }
    .header {
        display: grid;
        grid-template-columns: max-content max-content max-content max-content;
        align-items: center;
        grid-auto-flow: column;
    }
    .header .share-icon {
        font-size: 0.8em;
    }
    .header .save-status {
        position: relative;
        left: 0;
        transform: none;
        place-self: center;
        font-size: 0.7em;
        padding: 2px 5px 2px 5px;
    }
    .help-icon.mobile-only {
        position: absolute;
        right: 0;
        height: 15px;
    }
    .desktop-only {
        display: none;
    }
    .mobile-only {
        display: block;
    }
    .help-modal {
        top: 10vh;
        left: 10vw;
        right: 10vw;
        bottom: 10vh;
    }
    .help-modal-content {
        padding: 1em;
        font-size: 0.9em;
    }
    .help-modal-content h2 {
        font-size: 1em;
    }
}