@keyframes slot-glow {
    from {
        background-color: hsl(240 30% 40%);
    }
    to {
        background-color: hsl(240 30% 30%);
    }
}

.calendar {
    height: 100% !important;
}

.rbc-header button {
    text-transform: uppercase;
    font-weight: bold;
}

.rbc-time-gutter .rbc-timeslot-group {
    font-weight: bold;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    place-items: center;
}

.rbc-time-gutter .rbc-time-slot {
    background-color: transparent !important;
}

.rbc-time-gutter .rbc-timeslot-group div:first-child {
    border-left: none !important;
}

.rbc-time-slot {
    border-top: none !important;
}

.rbc-day-slot .time-slot-out-of-range {
    background-color: rgba(215, 215, 215, 0.95);
}

.rbc-day-slot .time-slot-selecting {
    background-color:rgba(220, 220, 255, 1) !important;
}

.rbc-day-slot .time-slot-glowing {
    animation-name: slot-glow;
    animation-duration: 0.5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.rbc-day-slot .time-slot-user::after {
    content: '';
    display: block;
    position: relative;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 18px,
        white 18px,
        white 19px
    );
}

.rbc-day-slot .rbc-timeslot-group {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 100%;
    place-items: center;
}

.slot-anchor {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    place-items: center;
}

.rbc-day-slot .slot-anchor .rbc-time-slot {
    height: 100%;
    width: 100%;
}

.rbc-time-gutter .slot-anchor {
    pointer-events: none;
}

.rbc-events-container {
    margin-right: 0 !important;
    pointer-events: none;
}

.rbc-event-label {
    display: none;
}

.rbc-event {
    border-radius: 0;
    border: 2px solid gold !important;
    border-right-width: 4px !important;
    outline: 2px solid gold !important;
    width: 100%;
    background-color: transparent !important;
    pointer-events: none;
}

.calendar-button-selected {
    background-color: rgba(255, 255, 255, 0.3);
}

@media (max-aspect-ratio: 1/1) {
    .rbc-header button {
        font-size: 0.5em;
    }
    .rbc-time-gutter {
        font-size: 0.5em;
    }
}