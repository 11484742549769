.calendar-toolbar {
    display: grid;
    padding: 1em;
    grid-template-rows: 100%;
    grid-template-columns: min-content 1fr max-content 1fr min-content;
    grid-template-areas: 'calendarPrev . calendarLabel . calendarNext';
}

.calendar-prev {
    grid-area: calendarPrev;
}

.calendar-label {
    grid-area: calendarLabel;
    text-transform: uppercase;
    font-family: Helvetica, sans-serif;
    font-weight: bold;
}

.calendar-next {
    grid-area: calendarNext;
}

.calendar-nav-arrow {
    font-size: 1.2em;
}

.calendar-nav-arrow:hover {
    transform: scale(1.2);
}

.calendar-nav-arrow:active {
    transform: scale(1.1);
}

@media (max-aspect-ratio: 1/1) {
    .calendar-label {
        font-size: 0.9em;
    }
}