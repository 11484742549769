.chat-container {
    min-height: 25rem;
    height: calc(80vh - 4rem);
    min-width: 20rem;
    width: 42.5vw;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.5);
    padding: 1em;
    display: grid;
    grid-template-rows: 90% 10%;
    grid-template-columns: 1fr;
    grid-template-areas:
        'messages'
        'input';
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.3em;
}

.messages-container {
    grid-area: messages;
    padding: 1em;
    display: grid;
    grid-auto-rows: min-content;
    overflow-y: auto;
    scrollbar-color: rgba(180, 180, 180, 0.5) rgba(180, 180, 180, 0.1);
    scrollbar-width: thin;
    scrollbar-gutter: stable;
}

.input-container {
    grid-area: input;
    border-top: 1px solid lightgray;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 90% 10%;
    grid-template-areas: 'msgin msgsend';
    padding-top: 0.5em;
    box-sizing: border-box;
}

.message {
    padding: 1em;
    place-items: center;
    border-radius: 10px;
    margin-bottom: 2em;
    max-width: 80%;
}

.message.incoming {
    background-color: rgba(200, 200, 230, 0.8);
    justify-self: start;
}

.message.outgoing {
    background-color: rgba(200, 200, 255, 0.5);
    justify-self: end;
}

.input-container .message-input {
    grid-area: msgin;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    height: 100%;
    padding: 0 1em 0 1em;
    border: none;
    outline: none;
}

.input-container .send {
    grid-area: msgsend;
    display: grid;
    place-items: center;
    background: none;
    border: none;
    outline: none;
    height: 100%;
}

.input-container .send-icon {
    height: 1.5em;
}

.input-container .send-icon.disabled {
    color: rgba(180, 180, 180, 0.8);
}

.input-container .send-icon.enabled {
    color: rgba(170, 200, 256, 1);
    --fa-fade-opacity: 0.6;
    --fa-beat-fade-opacity: 0.6;
    --fa-beat-fade-scale: 1.2;
}

.input-container .send-icon.enabled.clicking {
    --fa-beat-fade-scale: 1;
    --fa-beat-fade-opacity: 1;
}

@media (max-aspect-ratio: 1/1) {
    .chat-container {
        font-size: 1em;
        min-height: 20rem;
        height: 43vh;
        min-width: 15rem;
        width: 88vw;
    }
    .messages-container {
        font-size: 0.8em;
    }
    .input-container {
        font-size: 1em;
    }
}